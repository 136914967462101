export const isDemoMode = () => {
  return !!process.env.REACT_APP_DEMO_MODE && process.env.REACT_APP_DEMO_MODE === "true";
};

export const isDisableFeature = () => {
  return !!process.env.REACT_APP_DISABLE_MODE && process.env.REACT_APP_DISABLE_MODE === "true" && !isDemoMode();
};

export const isSpecificFeatureEnabled = (specificFeatureEnabled) => {
  return !isDisableFeature() || specificFeatureEnabled;
};

export const getImagesUrl = () => {
  return process.env.REACT_APP_LOYALTY_IMAGES_HOST;
};

export const getTierIconsUrl = () => {
  return `${getImagesUrl()}/icons/-1/tier`;
};

export const getGeneralIconsUrl = () => {
  return `${getImagesUrl()}/icons/-1/general`;
};

export const getEmarsysSupportAnchor = (text) => {
  return `<a target="_blank" href="https://help.emarsys.com/hc/en-us">${text}</a>`;
};

export const getApiBaseUrl = (customerId) => `${process.env.REACT_APP_LOYALTY_API}/customer/${customerId}`;

export const isDev = !!process.env.REACT_APP_IS_DEV && process.env.REACT_APP_IS_DEV === "true";
