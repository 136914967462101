import { createIntl, createIntlCache } from "react-intl";
import { en, de, es, tr, cs, ko, pl, pt, ru, ja, fr, zh_cn, zh_tw } from "../translations/translations";

const messages = {
  en,
  de,
  es,
  tr,
  cs,
  ko,
  pl,
  pt,
  ru,
  ja,
  fr,
  "zh-cn": zh_cn,
  "zh-tw": zh_tw
};

const availableLanguages = Object.keys(messages);

export const defaultMessages = messages.en;

let intl;
export const initIntl = () => {
  const cache = createIntlCache();
  const language = getAdminLanguage();
  intl = createIntl(
    {
      locale: language,
      messages: messages[language]
    },
    cache
  );
};

export const getMessage = (key, textObject) => {
  if (intl == undefined) {
    initIntl();
  }
  let result = "";
  if (textObject === undefined) {
    result = intl.formatMessage({
      id: key,
      defaultMessage: defaultMessages[key]
    });
  } else {
    result = intl.formatMessage(
      {
        id: key,
        defaultMessage: defaultMessages[key]
      },
      textObject
    );
  }

  return result;
};

export const getPluralCardinalMessage = (messageId, value, params) => {
  const pluralCategory = intl.formatPlural(value, { type: "cardinal" });
  messageId += `.plural.${pluralCategory}`;
  return getMessage(messageId, params);
};

export const getPluralOrdinalMessage = (messageId, value, params) => {
  const pluralCategory = intl.formatPlural(value, { type: "ordinal" });
  messageId += `.ordinal.${pluralCategory}`;
  return getMessage(messageId, params);
};

export const getAdminLanguage = () => {
  //take override language from session storage - for testing purposes
  const overrideLanguage = sessionStorage.getItem("lang");
  if (overrideLanguage) {
    return overrideLanguage.toLowerCase().replace("_", "-");
  }

  //get the language from the url generated by Emarsys - take only the first part of the local
  const queryParams = new URLSearchParams(document.location.search);
  const potentialLanguages = [];
  if (queryParams.get("language")) {
    const languageFromQueryString = queryParams.get("language").toLowerCase();
    potentialLanguages.push(languageFromQueryString.replace("_", "-"));
    potentialLanguages.push(languageFromQueryString.split("_")[0]);
  }

  const foundAvailableLanguage = potentialLanguages.find((lang) => availableLanguages.includes(lang));
  return foundAvailableLanguage || "en";
};
