import * as actionsEn from "./actions/en.json";
import * as dashboardEn from "./dashboard/en.json";
import * as exclusiveAccessEn from "./exclusiveAccess/en.json";
import * as generalEn from "./general/en.json";
import * as loyaltyManagementEn from "./loyaltyManagement/en.json";
import * as memberProfileEn from "./memberProfile/en.json";
import * as securitySettingsEn from "./securitySettings/en.json";
import * as tiersEn from "./tiers/en.json";
import * as voucherEn from "./voucher/en.json";

import * as actionsDe from "./actions/de.json";
import * as dashboardDe from "./dashboard/de.json";
import * as exclusiveAccessDe from "./exclusiveAccess/de.json";
import * as generalDe from "./general/de.json";
import * as loyaltyManagementDe from "./loyaltyManagement/de.json";
import * as memberProfileDe from "./memberProfile/de.json";
import * as securitySettingsDe from "./securitySettings/de.json";
import * as tiersDe from "./tiers/de.json";
import * as voucherDe from "./voucher/de.json";

import { actionsDeLegacy } from "./actions/de.legacy.js";
import { exclusiveAccessDeLegacy } from "./exclusiveAccess/de.legacy.js";
import { generalDeLegacy } from "./general/de.legacy.js";
import { memberProfileDeLegacy } from "./memberProfile/de.legacy.js";
import { securitySettingsDeLegacy } from "./securitySettings/de.legacy.js";
import { tiersDeLegacy } from "./tiers/de.legacy.js";
import { voucherDeLegacy } from "./voucher/de.legacy.js";

import * as actionsEs from "./actions/es.json";
import * as dashboardEs from "./dashboard/es.json";
import * as exclusiveAccessEs from "./exclusiveAccess/es.json";
import * as generalEs from "./general/es.json";
import * as loyaltyManagementEs from "./loyaltyManagement/es.json";
import * as memberProfileEs from "./memberProfile/es.json";
import * as securitySettingsEs from "./securitySettings/es.json";
import * as tiersEs from "./tiers/es.json";
import * as voucherEs from "./voucher/es.json";

import * as actionsCs from "./actions/cs.json";
import * as dashboardCs from "./dashboard/cs.json";
import * as exclusiveAccessCs from "./exclusiveAccess/cs.json";
import * as generalCs from "./general/cs.json";
import * as loyaltyManagementCs from "./loyaltyManagement/cs.json";
import * as memberProfileCs from "./memberProfile/cs.json";
import * as securitySettingsCs from "./securitySettings/cs.json";
import * as tiersCs from "./tiers/cs.json";
import * as voucherCs from "./voucher/cs.json";

import * as actionsFr from "./actions/fr.json";
import * as dashboardFr from "./dashboard/fr.json";
import * as exclusiveAccessFr from "./exclusiveAccess/fr.json";
import * as generalFr from "./general/fr.json";
import * as loyaltyManagementFr from "./loyaltyManagement/fr.json";
import * as memberProfileFr from "./memberProfile/fr.json";
import * as securitySettingsFr from "./securitySettings/fr.json";
import * as tiersFr from "./tiers/fr.json";
import * as voucherFr from "./voucher/fr.json";

import * as actionsJa from "./actions/ja.json";
import * as dashboardJa from "./dashboard/ja.json";
import * as exclusiveAccessJa from "./exclusiveAccess/ja.json";
import * as generalJa from "./general/ja.json";
import * as loyaltyManagementJa from "./loyaltyManagement/ja.json";
import * as memberProfileJa from "./memberProfile/ja.json";
import * as securitySettingsJa from "./securitySettings/ja.json";
import * as tiersJa from "./tiers/ja.json";
import * as voucherJa from "./voucher/ja.json";

import * as actionsKo from "./actions/ko.json";
import * as dashboardKo from "./dashboard/ko.json";
import * as exclusiveAccessKo from "./exclusiveAccess/ko.json";
import * as generalKo from "./general/ko.json";
import * as loyaltyManagementKo from "./loyaltyManagement/ko.json";
import * as memberProfileKo from "./memberProfile/ko.json";
import * as securitySettingsKo from "./securitySettings/ko.json";
import * as tiersKo from "./tiers/ko.json";
import * as voucherKo from "./voucher/ko.json";

import * as actionsPl from "./actions/pl.json";
import * as dashboardPl from "./dashboard/pl.json";
import * as exclusiveAccessPl from "./exclusiveAccess/pl.json";
import * as generalPl from "./general/pl.json";
import * as loyaltyManagementPl from "./loyaltyManagement/pl.json";
import * as memberProfilePl from "./memberProfile/pl.json";
import * as securitySettingsPl from "./securitySettings/pl.json";
import * as tiersPl from "./tiers/pl.json";
import * as voucherPl from "./voucher/pl.json";

import * as actionsPt from "./actions/pt.json";
import * as dashboardPt from "./dashboard/pt.json";
import * as exclusiveAccessPt from "./exclusiveAccess/pt.json";
import * as generalPt from "./general/pt.json";
import * as loyaltyManagementPt from "./loyaltyManagement/pt.json";
import * as memberProfilePt from "./memberProfile/pt.json";
import * as securitySettingsPt from "./securitySettings/pt.json";
import * as tiersPt from "./tiers/pt.json";
import * as voucherPt from "./voucher/pt.json";

import * as actionsRu from "./actions/ru.json";
import * as dashboardRu from "./dashboard/ru.json";
import * as exclusiveAccessRu from "./exclusiveAccess/ru.json";
import * as generalRu from "./general/ru.json";
import * as loyaltyManagementRu from "./loyaltyManagement/ru.json";
import * as memberProfileRu from "./memberProfile/ru.json";
import * as securitySettingsRu from "./securitySettings/ru.json";
import * as tiersRu from "./tiers/ru.json";
import * as voucherRu from "./voucher/ru.json";

import * as actionsTr from "./actions/tr.json";
import * as dashboardTr from "./dashboard/tr.json";
import * as exclusiveAccessTr from "./exclusiveAccess/tr.json";
import * as generalTr from "./general/tr.json";
import * as loyaltyManagementTr from "./loyaltyManagement/tr.json";
import * as memberProfileTr from "./memberProfile/tr.json";
import * as securitySettingsTr from "./securitySettings/tr.json";
import * as tiersTr from "./tiers/tr.json";
import * as voucherTr from "./voucher/tr.json";

import * as actionsZhCn from "./actions/zh_CN.json";
import * as dashboardZhCn from "./dashboard/zh_CN.json";
import * as exclusiveAccessZhCn from "./exclusiveAccess/zh_CN.json";
import * as generalZhCn from "./general/zh_CN.json";
import * as loyaltyManagementZhCn from "./loyaltyManagement/zh_CN.json";
import * as memberProfileZhCn from "./memberProfile/zh_CN.json";
import * as securitySettingsZhCn from "./securitySettings/zh_CN.json";
import * as tiersZhCn from "./tiers/zh_CN.json";
import * as voucherZhCn from "./voucher/zh_CN.json";

import * as actionsZhTw from "./actions/zh_TW.json";
import * as dashboardZhTw from "./dashboard/zh_TW.json";
import * as exclusiveAccessZhTw from "./exclusiveAccess/zh_TW.json";
import * as generalZhTw from "./general/zh_TW.json";
import * as loyaltyManagementZhTw from "./loyaltyManagement/zh_TW.json";
import * as memberProfileZhTw from "./memberProfile/zh_TW.json";
import * as securitySettingsZhTw from "./securitySettings/zh_TW.json";
import * as tiersZhTw from "./tiers/zh_TW.json";
import * as voucherZhTw from "./voucher/zh_TW.json";

export const en = {
  ...actionsEn,
  ...dashboardEn,
  ...exclusiveAccessEn,
  ...generalEn,
  ...loyaltyManagementEn,
  ...memberProfileEn,
  ...securitySettingsEn,
  ...tiersEn,
  ...voucherEn
};

export const de = {
  ...actionsDeLegacy,
  ...actionsDe,
  ...dashboardDe,
  ...exclusiveAccessDeLegacy,
  ...exclusiveAccessDe,
  ...generalDeLegacy,
  ...generalDe,
  ...loyaltyManagementDe,
  ...memberProfileDeLegacy,
  ...memberProfileDe,
  ...securitySettingsDeLegacy,
  ...securitySettingsDe,
  ...tiersDeLegacy,
  ...tiersDe,
  ...voucherDeLegacy,
  ...voucherDe
};

export const es = {
  ...actionsEs,
  ...dashboardEs,
  ...exclusiveAccessEs,
  ...generalEs,
  ...loyaltyManagementEs,
  ...memberProfileEs,
  ...securitySettingsEs,
  ...tiersEs,
  ...voucherEs
};

export const cs = {
  ...actionsCs,
  ...dashboardCs,
  ...exclusiveAccessCs,
  ...generalCs,
  ...loyaltyManagementCs,
  ...memberProfileCs,
  ...securitySettingsCs,
  ...tiersCs,
  ...voucherCs
};

export const fr = {
  ...actionsFr,
  ...dashboardFr,
  ...exclusiveAccessFr,
  ...generalFr,
  ...loyaltyManagementFr,
  ...memberProfileFr,
  ...securitySettingsFr,
  ...tiersFr,
  ...voucherFr
};

export const ja = {
  ...actionsJa,
  ...dashboardJa,
  ...exclusiveAccessJa,
  ...generalJa,
  ...loyaltyManagementJa,
  ...memberProfileJa,
  ...securitySettingsJa,
  ...tiersJa,
  ...voucherJa
};

export const ko = {
  ...actionsKo,
  ...dashboardKo,
  ...exclusiveAccessKo,
  ...generalKo,
  ...loyaltyManagementKo,
  ...memberProfileKo,
  ...securitySettingsKo,
  ...tiersKo,
  ...voucherKo
};

export const pl = {
  ...actionsPl,
  ...dashboardPl,
  ...exclusiveAccessPl,
  ...generalPl,
  ...loyaltyManagementPl,
  ...memberProfilePl,
  ...securitySettingsPl,
  ...tiersPl,
  ...voucherPl
};

export const pt = {
  ...actionsPt,
  ...dashboardPt,
  ...exclusiveAccessPt,
  ...generalPt,
  ...loyaltyManagementPt,
  ...memberProfilePt,
  ...securitySettingsPt,
  ...tiersPt,
  ...voucherPt
};

export const ru = {
  ...actionsRu,
  ...dashboardRu,
  ...exclusiveAccessRu,
  ...generalRu,
  ...loyaltyManagementRu,
  ...memberProfileRu,
  ...securitySettingsRu,
  ...tiersRu,
  ...voucherRu
};

export const tr = {
  ...actionsTr,
  ...dashboardTr,
  ...exclusiveAccessTr,
  ...generalTr,
  ...loyaltyManagementTr,
  ...memberProfileTr,
  ...securitySettingsTr,
  ...tiersTr,
  ...voucherTr
};

export const zh_tw = {
  ...actionsZhTw,
  ...dashboardZhTw,
  ...exclusiveAccessZhTw,
  ...generalZhTw,
  ...loyaltyManagementZhTw,
  ...memberProfileZhTw,
  ...securitySettingsZhTw,
  ...tiersZhTw,
  ...voucherZhTw
};

export const zh_cn = {
  ...actionsZhCn,
  ...dashboardZhCn,
  ...exclusiveAccessZhCn,
  ...generalZhCn,
  ...loyaltyManagementZhCn,
  ...memberProfileZhCn,
  ...securitySettingsZhCn,
  ...tiersZhCn,
  ...voucherZhCn
};
